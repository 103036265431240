import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const BlogShra = ({data}) => {
    return <Layout>
        <Helmet>
            <title>{data.allMarkdownRemark.nodes[0].frontmatter.title}</title>
            <meta name="description" content={data.allMarkdownRemark.nodes[0].excerpt} />
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org/",
                  "@type": "Article",
                  "name": "${data.allMarkdownRemark.nodes[0].frontmatter.title}",
                  "author": "namastegod",
                  "headline": "${data.allMarkdownRemark.nodes[0].frontmatter.link_title}",
                  "description": "${data.allMarkdownRemark.nodes[0].excerpt}",
                  "image": "${data.site.siteMetadata.url}${data.allMarkdownRemark.nodes[0].frontmatter.photo.childImageSharp.fluid.src}",
                  "publisher": {
                    "@type": "Organization",
                    "name": "NamasteGod",
                    "url": "${data.site.siteMetadata.url}",
                    "logo": {
                      "@type": "ImageObject",
                      "url": "https://lh4.googleusercontent.com/-EXCsRc5tnmc/AAAAAAAAAAI/AAAAAAAAAAA/u-HcCeFyTic/s44-p-k-no-ns-nd/photo.jpg"
                    }
                  },
                  "about": {
                    "@type": "Service",
                    "serviceType": "${data.allMarkdownRemark.nodes[0].frontmatter.puja}"
                  },
                  "datePublished": "${data.allMarkdownRemark.nodes[0].frontmatter.date}",
                  "dateModified" : "${data.allMarkdownRemark.nodes[0].frontmatter.date}",
                  "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "${data.site.siteMetadata.url}"
                  }
                }
            `}</script>
        </Helmet>
        <div id="main" className="blog-post">
            <section id="one">
                <div className="inner">
                    <header>
                        <h1>{data.allMarkdownRemark.nodes[0].frontmatter.title}</h1>
                    </header>
                    <div className="details">
                      <span className="date">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                        <span>{data.allMarkdownRemark.nodes[0].frontmatter.date}</span>
                      </span>
                      <span className="author">
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <span>{data.allMarkdownRemark.nodes[0].frontmatter.author}</span>
                      </span>
                      <span className="type">
                        <i class="fa fa-folder" aria-hidden="true"></i>
                        <span>{data.allMarkdownRemark.nodes[0].frontmatter.puja_type}</span>
                      </span>
                    </div>

                    <div className="content" dangerouslySetInnerHTML={{ __html: data.allMarkdownRemark.nodes[0].html }} />
                    <Link className="button special" to={data.allMarkdownRemark.nodes[0].frontmatter.link}>{data.allMarkdownRemark.nodes[0].frontmatter.link_title}</Link>
                </div>
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/posts/durga/"}}) {
    nodes {
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        title
        author
        puja_type
        link_title
        link
        photo {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        puja
      }
      html
      excerpt(pruneLength: 150)
    }
  }
  site {
    siteMetadata {
      url
    }
  }
}`

export default BlogShra